import { makeStyles, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 400,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',

    // mobile
    [theme.breakpoints.down('xs')]: {
      minWidth: 1,
    },
  },
  captions: {
    marginBottom: theme.spacing(2),
  },
  dialogCaption: {
    fontWeight: 400,
    fontSize: '1.375rem',
    maxWidth: 300,
    margin: '0 auto',
    textAlign: 'center',
    lineHeight: '1.2',
    marginBottom: theme.spacing(5),
    '&:nth-last-of-type(1)': {
      marginBottom: 0,
    },
  },
  guestLink: {
    textDecoration: 'underline',
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
  accountLink: {
    textAlign: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  banner: {
    width: 250,
    height: 185,
    alignSelf: 'center',

    [`${theme.breakpoints.down('xs')} and (orientation: landscape)`]: {
      width: 150,
      height: 112,
    },
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textDecoration: 'none',
    cursor: 'pointer',
  },
}))
