import useStyles from './styles'
import Typography from '@material-ui/core/Typography'
import content from 'translates/en/common.json'
import { getDataFromJson } from 'src/utils/helpers'

const t = getDataFromJson(content)

type Props = {
  type: 'invited_by' | null
}
function AuthWaysTitle(props: Props) {
  const classes = useStyles(props)

  switch (props.type) {
    case 'invited_by': {
      return (
        <Typography variant='h6' className={classes.dialogCaption}>
          <b>{t('Register')}</b> {t('now to activate')}
          <br />
          {t('your')} <b>{t('Sweepstake Coins')}</b>
        </Typography>
      )
    }
    default: {
      return (
        <Typography variant='h6' className={classes.dialogCaption}>
          {t('Get your')} <b>{t('free coins')}</b>
          <br />
          {t('and')} <b>{t('start winning')}</b> {t('now')}
        </Typography>
      )
    }
  }
}

export default AuthWaysTitle
