import HomeLoggedOut from 'pageContainers/Home/components/HomeLoggedOut'
import Head from 'next/head'
import metaData from 'translates/en/meta/mainPages.json'

function HomePage() {
  const title = `${metaData.home.title} ${process.env.BRAND_NAME === 'onl' ? ' & Lotto' : ''}`

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name='description' content={metaData.home.meta} />
      </Head>

      <HomeLoggedOut />
    </>
  )
}

export default HomePage
