import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  root: {
    padding: '16px 0',
    display: 'flex',
    flexDirection: 'column',
  },
  authBlock: {
    background: '#fff',
    marginTop: -16,
    paddingTop: 16,
    paddingBottom: 16,
  },
  loginLink: {
    marginBottom: 0,
  },
}))
