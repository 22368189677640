import { useSelector } from 'react-redux'
import Link from 'next/link'
import Typography from '@material-ui/core/Typography'
import content from 'translates/en/common.json'
import { getDataFromJson } from 'src/utils/helpers'
import { ReactNode } from 'react'
import cx from 'classnames'
import { freeLottoAllowedSelector } from 'store/modules/games/selectors'
import AuthWaysTitle from './AuthWaysTitle'
import ROUTES from 'config/routes.json'
import useStyles from './styles'

const t = getDataFromJson(content)

type Props = {
  children: ReactNode
  queryParams?: {
    type: 'invited_by' | null
  }
  loginLinkClass?: string
}
export default function AuthContent(props: Props) {
  const classes = useStyles(props)
  const freeLottoAllowed = useSelector(freeLottoAllowedSelector)
  const type = props.queryParams?.type

  return (
    <>
      <div className={classes.root}>
        <div className={classes.captions}>
          {freeLottoAllowed && (
            <Typography variant='h6' className={classes.dialogCaption}>
              {t('Win up to $25 Million Real Cash')}!
            </Typography>
          )}

          <AuthWaysTitle type={type} />
        </div>

        <img className={classes.banner} src={`${process.env.IMG_ORIGIN}/sign-in.png`} alt='sign in' />
        {props.children}
      </div>
      <Typography variant='subtitle1' align='center' className={cx(classes.accountLink, props?.loginLinkClass)}>
        {t('Already got an account')}?{' '}
        <Link href={ROUTES.LOGIN} prefetch={false}>
          <a className={classes.link}>{t('Log in')} &gt;</a>
        </Link>
      </Typography>
    </>
  )
}
