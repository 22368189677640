import { connect } from 'react-redux'
import { useRouter } from 'next/router'
import PageContent from './Content'
import {
  getVisibleAndSortedLottosSelector,
  getTopGamesSelector,
  getHoldAndWinGamesSelector,
  getNewGamesSelector,
  getSlotProductsSelector,
  getVisibleTableGamesSelector,
  getProgJackpotsSelector,
  getEpicWinsGamesSelector,
  getClassicGamesSelector,
  getMegawaysGamesSelector,
} from 'store/modules/games/selectors'
import { startLottoGame } from 'store/modules/lottoGameFlow/actions'
import { LottoProduct, GameProduct } from 'store/modules/games/types'
import { ApplicationState } from 'store/modules/types'
import { useEffect } from 'react'
import { isLoggedInSelector } from 'src/store/modules/user/selectors'
import ROUTES from 'config/routes.json'

const mapState = (state: ApplicationState) => ({
  visibleAndSortedLottos: getVisibleAndSortedLottosSelector(state),
  slotGames: getSlotProductsSelector(state),
  tableGames: getVisibleTableGamesSelector(state),
  newGames: getNewGamesSelector(state),
  topGames: getTopGamesSelector(state),
  jackpotGames: getProgJackpotsSelector(state),
  megawaysGames: getMegawaysGamesSelector(state),
  classicGames: getClassicGamesSelector(state),
  epicWinsGames: getEpicWinsGamesSelector(state),
  holdAndWinGames: getHoldAndWinGamesSelector(state),
  loggedIn: isLoggedInSelector(state),
})

const mapDispatch = {
  startLottoGame,
}

type Props = {
  visibleAndSortedLottos: Array<LottoProduct>
  slotGames: Array<GameProduct>
  tableGames: Array<GameProduct>
  newGames: Array<GameProduct>
  topGames: Array<GameProduct>
  jackpotGames: Array<GameProduct>
  holdAndWinGames: Array<GameProduct>
  megawaysGames: Array<GameProduct>
  classicGames: Array<GameProduct>
  epicWinsGames: Array<GameProduct>
  startLottoGame: (game: LottoProduct) => void
  loggedIn: boolean
}

function HomeLoggedOut(props: Props) {
  const router = useRouter()

  // this page visible only for logged out users
  // redirect to "/home" when logged in
  useEffect(() => {
    if (props.loggedIn) {
      router.push(ROUTES.HOME_LOGGED_IN)
    }
  }, [props.loggedIn])

  return (
    <>
      <PageContent
        visibleAndSortedLottos={props.visibleAndSortedLottos}
        slotGames={props.slotGames}
        tableGames={props.tableGames}
        newGames={props.newGames}
        topGames={props.topGames}
        startLottoGame={props.startLottoGame}
        holdAndWinGames={props.holdAndWinGames}
        jackpotGames={props.jackpotGames}
        megawaysGames={props.megawaysGames}
        epicWinsGames={props.epicWinsGames}
        classicGames={props.classicGames}
      />
    </>
  )
}

export default connect(mapState, mapDispatch)(HomeLoggedOut)
