import Hidden from '@material-ui/core/Hidden'
import useStyles from './styles'
import SeoContent from '../SeoContent'
import HomeBanners from 'containers/HomeBanners/LoggedOut'
import AuthContent from 'containers/AuthContent'
import { LottoProduct, GameProduct } from 'store/modules/games/types'
import useIsReactNative from 'hooks/useIsReactNative'
import CreateAccountButton from 'src/containers/CreateAccountButton'
import ROUTES from 'config/routes.json'

import LottoItems from '../LottoItems'
import {
  NewBox,
  SlotMachineOutline,
  PokerChip,
  ArrowUpBoldBoxOutline,
  Fingerprint,
  CardsPlayingOutline,
  RocketLaunchOutline,
  Atom,
} from 'mdi-material-ui'
import SlotsRow from '../SlotsRow'

type Props = {
  visibleAndSortedLottos: Array<LottoProduct>
  slotGames: Array<GameProduct>
  tableGames: Array<GameProduct>
  newGames: Array<GameProduct>
  topGames: Array<GameProduct>
  jackpotGames: Array<GameProduct>
  holdAndWinGames: Array<GameProduct>
  megawaysGames: Array<GameProduct>
  classicGames: Array<GameProduct>
  epicWinsGames: Array<GameProduct>
  startLottoGame: (game: LottoProduct) => void
}

function Home(props: Props) {
  const classes = useStyles(props)
  const { visibleAndSortedLottos, startLottoGame } = props
  const isAndroidApp = useIsReactNative()

  return (
    <div className={classes.root}>
      <Hidden only={['xs']} implementation='css'>
        <HomeBanners />
      </Hidden>

      {!isAndroidApp && (
        <Hidden only={['sm', 'md', 'lg', 'xl']} implementation='css'>
          <div className={classes.authBlock} data-test='home-auth-block'>
            <AuthContent loginLinkClass={classes.loginLink}>
              <CreateAccountButton />
            </AuthContent>
          </div>
        </Hidden>
      )}

      {!!visibleAndSortedLottos.length && (
        <LottoItems
          activeCurrency='GC'
          visibleAndSortedLottos={visibleAndSortedLottos}
          startGameFlow={startLottoGame}
        />
      )}

      <SlotsRow
        games={props.newGames?.slice(0, 12)}
        title='New Games'
        Icon={NewBox}
        url={ROUTES.GAMES_NEW}
        count={props.newGames?.length}
      />

      <SlotsRow
        games={props.topGames?.slice(0, 12)}
        lines={2}
        title='Top Games'
        Icon={ArrowUpBoldBoxOutline}
        url={ROUTES.GAMES_TOP}
        count={props.topGames?.length}
      />

      <SlotsRow
        games={props.epicWinsGames?.slice(0, 12)}
        title='Epic Wins'
        Icon={RocketLaunchOutline}
        url={ROUTES.GAMES_EPIC}
        count={props.epicWinsGames?.length}
      />
      <SlotsRow
        games={props.megawaysGames?.slice(0, 12)}
        title='Megaways'
        Icon={Atom}
        url={ROUTES.MEGAWAYS}
        count={props.megawaysGames?.length}
      />

      <SlotsRow
        games={props.classicGames?.slice(0, 12)}
        title='Classic Games'
        Icon={SlotMachineOutline}
        url={ROUTES.GAMES_CLASSIC}
        count={props.classicGames?.length}
      />

      <SlotsRow
        games={props.holdAndWinGames?.slice(0, 12)}
        title='Hold and Win'
        Icon={Fingerprint}
        url={ROUTES.GAMES_HAW}
        count={props.holdAndWinGames?.length}
      />

      <SlotsRow
        games={props.jackpotGames?.slice(0, 12)}
        title='Jackpots'
        Icon={CardsPlayingOutline}
        url={ROUTES.GAMES_JACKPOTS}
        count={props.jackpotGames?.length}
      />

      <SlotsRow
        games={props.slotGames?.slice(0, 18)}
        lines={3}
        title='Slots'
        Icon={SlotMachineOutline}
        url={ROUTES.GAMES_SLOTS}
        count={props.slotGames?.length}
      />

      <SlotsRow
        games={props.tableGames?.slice(0, 12)}
        title='Table Games'
        Icon={PokerChip}
        url={ROUTES.GAMES_TABLE}
        count={props.tableGames?.length}
      />

      <SeoContent />
    </div>
  )
}

export default Home
