import { makeStyles, fade, Theme } from '@material-ui/core/styles'

export default makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    minHeight: 48,
  },
  loading: {
    padding: 0,
    background: `${fade(theme.palette.common.black, 0.12)} !important`,
    '&:disabled': {
      background: `${fade(theme.palette.common.black, 0.12)} !important`,
    },
    '&:hover': {
      boxShadow: 'none',
    },
  },
  text: {
    fontSize: '1.1rem',
    fontWeight: 'normal',
  },
}))
