import { connect } from 'react-redux'
import HorizontalScroll from 'containers/HorizontalScroll'
import GridWrapper from 'components/GridWrapper'

import { openDialog } from 'store/modules/dialog/actions'
import FirstTimeGCOffers from './banners/FirstTimeGCOffers'
import Invite from './banners/Invite'

import { ApplicationState } from 'src/store/modules/types'
import { isLoggedInSelector } from 'src/store/modules/user/selectors'

type Props = {
  openDialog: typeof openDialog
  loggedIn: boolean
}

const mapState = (state: ApplicationState) => ({
  loggedIn: isLoggedInSelector(state),
})
const mapDispatch = {
  openDialog,
}

function HomeBanners(props: Props) {
  return (
    <HorizontalScroll autoScrollTimeInterval={5000} scrolledItemDataAttr='data-banner'>
      <GridWrapper>
        <FirstTimeGCOffers />
        <Invite openDialog={props.openDialog} isLoggedIn={props.loggedIn} />
      </GridWrapper>
    </HorizontalScroll>
  )
}

export default connect(mapState, mapDispatch)(HomeBanners)
