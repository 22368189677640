import Button from 'components/Button'
import { useSelector } from 'react-redux'
import Loader from 'src/components/Loader'
import { getAuthFlowLoadingSelector } from 'src/store/modules/auth/selectors'
import cx from 'classnames'
import { useRouter } from 'next/router'
import ROUTES from 'config/routes.json'

import useStyles from './styles'

function CreateAccountButton() {
  const classes = useStyles()
  const router = useRouter()
  const isLoading = useSelector(getAuthFlowLoadingSelector)
  const onClick = () => {
    router.push(ROUTES.REGISTER)
  }

  return (
    <Button
      disabled={isLoading}
      className={cx(classes.root, isLoading && classes.loading)}
      onClick={onClick}
      data-test='create-account'
      color='SC'
    >
      {isLoading ? (
        <Loader size={28} />
      ) : (
        <>
          <span className={classes.text}>CREATE YOUR ACCOUNT</span>
        </>
      )}
    </Button>
  )
}

export default CreateAccountButton
